import styled from "styled-components"
import Img from "gatsby-image"

const GalleryImage = styled(Img)`
  height: 0;
  padding-bottom: 100%;
  border: 5px solid ${props => props.theme.colors.imageBorder.grey};
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.01);
  }
`

export default GalleryImage
