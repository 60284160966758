import styled from "styled-components"

const GalleryContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }

  @media (${props => props.theme.mediaQueries.largeDesktop}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
  }
`

export default GalleryContainer
