import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import { SRLWrapper } from "simple-react-lightbox"

//React components imports
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"

//Styled components imports
import GalleryImage from "../shared-styled-components/GalleryImage"
import GalleryContainer from "../shared-styled-components/GalleryContainer"
import Seo from "../components/Seo"
import GalleryLink from "../shared-styled-components/GalleryLink"

const Gallery = props => {
  const [subtitle, setSubtitle] = useState("")

  useEffect(() => {
    const setSubtitleFromPathname = () => {
      switch (props.location.pathname) {
        case "/galeria/izby-hotel-dolina":
          setSubtitle("Izby hotel Dolina")
          break
        case "/galeria/kaviaren-a-jedalen":
          setSubtitle("Kaviareň a jedáleň")
          break
        default:
          setSubtitle("Hotel Dolina")
      }
    }

    setSubtitleFromPathname()
  }, [props.location.pathname])

  const renderContent = () => {
    return props.data.allFile.edges.map(({ node }) => {
      if (
        props.location.pathname ===
        `/galeria/${node.childImageSharp.fields.slug}`
      ) {
        return (
          <Link
            to={node.childImageSharp.fluid.src}
            data-attribute="SRL"
            style={{ filter: "none" }}
          >
            <GalleryImage key={node.id} fluid={node.childImageSharp.fluid} />
          </Link>
        )
      } else {
        return null
      }
    })
  }

  return (
    <Layout>
      <Seo
        description={`Galéria hotelu Dolina. ${subtitle}.`}
        url={`http://www.hoteldolina.eu/galeria/${props.location.pathname}`}
        keywords={["galeria hotel dolina", subtitle]}
      />
      <PageTitle subtitle={subtitle}>
        <GalleryLink to="/galeria">Galéria Hotel Dolina</GalleryLink>
      </PageTitle>
      <SRLWrapper>
        <GalleryContainer>{renderContent()}</GalleryContainer>
      </SRLWrapper>
    </Layout>
  )
}

export default Gallery

export const query = graphql`
  {
    allFile(filter: { absolutePath: { regex: "/(gallery)/" } }) {
      edges {
        node {
          childImageSharp {
            fields {
              slug
            }
            fluid {
              ...GatsbyImageSharpFluid
              src
            }
          }
        }
      }
    }
  }
`
